import { Checkbox, Input, Radio } from 'antd';
import { useMemo } from 'react';
import { numberToLetter } from 'src/utils/tools';
import { IQuestionItem, QuestionType } from 'src/api/types/exam';

interface IProps {
  data: IQuestionItem;
}

export function QuestionAnswer(props: IProps) {
  const { data } = props;
  const { questionType, options = [], answer = [], textAnswer } = data || {};

  const formatOptions = useMemo(() => {
    if (questionType === QuestionType.Judgment) {
      return [
        { label: '正确', value: 1 },
        { label: '错误', value: 0 },
      ];
    }

    return options?.map((item, index) => {
      return {
        label: (
          <div className="flex justify-start gap-2 items-center">
            <span>{numberToLetter(index + 1)}.</span>
            {item}
          </div>
        ),
        value: index,
      };
    });
  }, [options, questionType]);

  if ([QuestionType.SingleChoice, QuestionType.Judgment].includes(questionType)) {
    return (
      <Radio.Group
        value={Number(answer?.[0])}
        disabled
        className="flex flex-col gap-2"
        options={formatOptions}></Radio.Group>
    );
  }
  if ([QuestionType.MultipleChoice, QuestionType.UnCertainMultiple].includes(questionType)) {
    return (
      <Checkbox.Group
        value={answer?.map(Number)}
        disabled
        className="flex flex-col gap-2"
        options={formatOptions}
      />
    );
  }
  if (QuestionType.QuestionAndAnswer === questionType) {
    return <Input.TextArea value={textAnswer?.[0]} disabled style={{ resize: 'none' }} rows={5} />;
  }

  if (QuestionType.FillInBlanks === questionType) {
    return textAnswer?.map((item, index) => {
      return (
        <div key={index} className="flex items-center justify-start gap-2 mb-3">
          <span>{index + 1}. </span>
          <Input value={item} disabled style={{ width: '100%' }} />
        </div>
      );
    });
  }
  return null;
}
