import type { IFormSchema } from 'src/components/Form';

export const schema: IFormSchema = {
  paperName: {
    type: 'string',
    title: '试卷名称',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入试卷名称',
    },
    'x-disabled': true,
    'x-decorator-props': {
      gridSpan: 3,
    },
    'x-validator': [{ required: true, message: '请输入试卷名称' }],
  },
  randomOption: {
    type: 'boolean',
    title: '随机选项',
    'x-component': 'Radio.Group',
    'x-decorator': 'FormItem',
    'x-component-props': {
      options: [
        { label: '是', value: true },
        { label: '否', value: false },
      ],
      optionType: 'button',
      block: true,
      buttonStyle: 'solid',
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
    'x-validator': [{ required: true, message: '请选择随机选项' }],
  },
  randomQuestion: {
    type: 'boolean',
    title: '随机题目',
    'x-component': 'Radio.Group',
    'x-decorator': 'FormItem',
    'x-component-props': {
      options: [
        { label: '是', value: true },
        { label: '否', value: false },
      ],
      optionType: 'button',
      block: true,
      buttonStyle: 'solid',
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
    'x-validator': [{ required: true, message: '请选择随机题目' }],
  },
  duration: {
    type: 'number',
    title: '考试时长(分钟)',
    'x-component': 'NumberPicker',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入考试时长',
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
    'x-validator': [{ required: true, message: '请输入考试时长' }],
  },
};
