import { useBoolean, useMemoizedFn, useRequest } from 'ahooks';
import { Button, Card, message, Modal, Spin } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Radio, Input, NumberPicker } from '@formily/antd-v5';
import { onFormMount } from '@formily/core';
import { isNumber, omit } from 'lodash-es';
import scrollIntoView from 'scroll-into-view-if-needed';
import { queryStringToObject } from 'src/utils/tools';
import PageHeaderBar from 'src/components/PageHeaderBar';
import {
  getPaperVersionInfo,
  createPaperVersion,
  deleteQuestionByPaper,
  addQuestionsToPaperVersion,
  deletePaperVersion,
  updateVersion,
  addQuestionToPaperVersion,
  updateQuestionInPaperVersion,
} from 'src/api/clients';
import { Form, FormRef } from 'src/components/Form';
import { schema } from './config';
import { AddIcon } from './components/addIcon';
import { BankAddQuestion } from './components/BankAddQuestion';
import { DragContainer, IDragEndExtraParams } from './components/DragContainer';
import { Question } from './components/Questions';
import { IQuestionItem } from 'src/api/types/exam';
import { InsertType } from 'src/constants/exam';
import { CreateQuestion } from '../components/CreateQuestion';

export default function TestPaperInfo() {
  const navigate = useNavigate();
  const formRef = useRef<FormRef | null>(null);
  const [isMount, setIsMount] = useState(false);
  const [visible, { setTrue, setFalse }] = useBoolean(false);
  const [list, setList] = useState<Array<IQuestionItem & { score?: number }>>([]);
  const [activeId, setActiveId] = useState<number>(0);
  const [currentEditQuestion, setCurrentEditQuestion] = useState<IQuestionItem>();
  const [type, setType] = useState<InsertType>(InsertType.bottom);
  const [version, setVersion] = useState<string | null>(null);
  const [submitLoading, { setTrue: setSubmitTrue, setFalse: setSubmitFalse }] = useBoolean(false);
  const [visibleQuestion, { setTrue: setVisibleTrue, setFalse: setVisibleFalse }] =
    useBoolean(false);
  const [messageApi, contextHolder] = message.useMessage();
  const query = queryStringToObject(window.location.search);
  const [modal, contextModalHolder] = Modal.useModal();
  const { data, loading } = useRequest(async () => {
    if (!query.id) {
      message.error('试卷ID不存在');
      return;
    }
    const version = await createPaperVersion({ paperId: query.id });
    if (!version) {
      message.error('创建试卷版本失败');
      return;
    }
    setVersion(version);
    const res = await getPaperVersionInfo(version);
    setList(res?.questions?.map((i) => ({ ...i.question, score: i.questionScore })) || []);
    return res;
  });
  // 删除试卷信息
  const deletePaper = useMemoizedFn(async () => {
    if (version) {
      await deletePaperVersion(version || '');
    }
  });

  // 获取试卷信息
  const getQuestionList = useMemoizedFn(async () => {
    if (!version) {
      message.error('试卷ID不存在');
      return;
    }
    const res = await getPaperVersionInfo(version);
    setList(res?.questions?.map((i) => i.question) || []);
  });

  const goBack = useMemoizedFn(async () => {
    await deletePaper();
    navigate('/exam/testpaper');
  });

  const handleDragEnd = (_list: IQuestionItem[], result: IDragEndExtraParams) => {
    const { newIndex, oldIndex } = result || {};
    if (oldIndex === activeId && isNumber(newIndex)) {
      setActiveId(newIndex);
    }
  };

  const removeQuestionHandle = useMemoizedFn(async (id: string) => {
    if (!version) {
      messageApi.error('创建版本信息失败，请刷新页面');
    }
    messageApi.open({
      type: 'loading',
      content: '正在删除，请稍后...',
      duration: 0,
    });
    try {
      setList((list) => list?.filter((item) => item.questionId !== id));
      await deleteQuestionByPaper({ paperVersionId: version || '', questionId: id });
    } finally {
      messageApi.destroy();
    }
    // messageApi.info("已删除")
  });

  const handleCreateTestpaper = (type: InsertType) => {
    setType(type);
    setVisibleTrue();
  };

  const handleEditTestpaper = useMemoizedFn((data: IQuestionItem) => {
    setCurrentEditQuestion(data);
    setVisibleTrue();
  });

  const getAfterInsertIds = useMemoizedFn((ids: string[]) => {
    const isExist = ids.some((id) => {
      return list?.some((item) => item.questionId === id);
    });
    if (isExist) {
      messageApi.error('插入试题重复，请检查后添加');
      return;
    }

    const key = type === InsertType.top ? (activeId - 1 > -1 ? activeId - 1 : 0) : activeId;
    const questionIds = list?.map((item) => item.questionId);
    questionIds.splice(key, 0, ...ids);
    return questionIds;
  });

  const onInsertQuestion = useMemoizedFn(
    async (
      val: Omit<IQuestionItem, 'questionLibraryId' | 'questionId'> & { questionId?: string },
    ) => {
      const requestFn = val?.questionId ? updateQuestionInPaperVersion : addQuestionToPaperVersion;
      const requestData = {
        paperVersionId: query.id || '',
        questionId: val?.questionId || '',
        question: omit(val, 'questionId'),
      };
      const newQuestionId = await requestFn(requestData);
      // 找到所在位置
      const index = val?.questionId
        ? list?.findIndex((item) => item.questionId === val.questionId)
        : type === InsertType.top
          ? activeId
          : activeId + 1;

      setList((list) => {
        const newList = [...list];
        newList.splice(index < 0 ? 0 : index, 0, { ...val, questionId: newQuestionId });
        return newList;
      });
    },
  );

  const onInsertBank = useMemoizedFn(async (ids: string[]) => {
    if (!version) {
      messageApi.error('创建版本信息失败，请刷新页面');
    }
    // 判断新增的题目是否已经存在
    const list = getAfterInsertIds(ids);
    if (!list?.length) return;
    await addQuestionsToPaperVersion({ paperVersionId: version || '', questions: list });
    await getQuestionList();
  });

  const onPaperSubmit = useMemoizedFn(async () => {
    // 实际提交信息
    const instance = formRef?.current?.getFormInstance();
    const values = (await instance?.submit()) as {
      paperName: string;
      randomOption: boolean;
      randomQuestion: boolean;
      duration: number;
    };
    // 判断是否填写score
    list?.forEach((item) => {
      if (!item.score) {
        messageApi.error('请设置题目分数');
        const dom = document.querySelector(`#question_${item.questionId}`);
        if (dom) {
          scrollIntoView(dom, {
            scrollMode: 'if-needed',
            block: 'center',
            inline: 'center',
            behavior: 'smooth',
          });
          return;
        }
      }
    });
    // 获取 id = score映射
    const getScoreByList = list?.map(({ questionId, score }) => ({
      questionId,
      questionScore: score || 0,
    }));
    setSubmitTrue();
    try {
      await updateVersion({
        paperVersionId: version || '',
        scoreInfo: getScoreByList,
        ...(omit(values, 'paperName') as any),
      });
      modal.info({
        title: '提示',
        content: '操作成功，请在历史版本中进行查看与发布',
        onOk: () => {
          navigate('/exam/testpaper');
        },
        okText: '我知道了',
        cancelText: null,
      });
    } finally {
      setSubmitFalse();
    }
  });

  const formOptions = useMemo(() => {
    return {
      effects() {
        onFormMount(() => {
          setIsMount(true);
        });
      },
    };
  }, []);

  useEffect(() => {
    window.onbeforeunload = function (event) {
      event.returnValue = '';
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    const instance = formRef?.current?.getFormInstance();
    if (isMount && instance) {
      instance.setValues({
        paperName: data?.paper?.paperName,
        randomOption: data?.randomOption,
        randomQuestion: data?.randomQuestion,
        duration: data?.duration,
      });
    }
  }, [data, isMount]);

  return (
    <Card className="m-4 rounded-md" styles={{ body: { padding: 0 } }} loading={loading}>
      <PageHeaderBar title="编辑试卷" onBack={goBack} />
      <div className="p-6 w-3/5 xs:w-full md:w-4/5 sm:w-full lg:w-3/5 xl:w-3/5 xxl:w-2/5 mx-auto">
        <Form
          ref={formRef}
          schema={schema}
          formOptions={formOptions}
          components={{ Radio, Input, NumberPicker }}
          hideAction
          grid={{ maxColumns: 3 }}
          layout={{ layout: 'vertical', gridColumnGap: 3 }}
        />
        <div className="py-6">
          <div className="text-black dark:text-white text-base font-semibold mb-4">题目设置</div>
          <Spin spinning={false}>
            {!list?.length ? (
              <div className="relative">
                <div className="relative z-[2] flex justify-center">
                  <AddIcon
                    disabled={!query.id}
                    handleCreateTestpaper={() => handleCreateTestpaper(InsertType.top)}
                    openInsertBank={setTrue}
                  />
                </div>
                <div className="h-[1px] w-full border border-dashed border-[#e8e8e8] absolute top-1/2 left-1/2 -translate-x-[50%] z-[1]" />
              </div>
            ) : (
              <DragContainer list={list || []} onDragEnd={handleDragEnd}>
                {(list || [])?.map((item, index) => (
                  <Question
                    data={item}
                    index={index}
                    key={item.questionId}
                    activeId={activeId}
                    onChangeActiveId={setActiveId}
                    openInsertBank={(type: InsertType) => {
                      setTrue();
                      setType(type);
                    }}
                    removeQuestion={removeQuestionHandle}
                    handleEditTestpaper={handleEditTestpaper}
                    handleCreateTestpaper={handleCreateTestpaper}
                    editQuestion={(score) => {
                      const newList = [...list];
                      newList[index] = { ...newList[index], score };
                      setList(newList);
                    }}
                  />
                ))}
              </DragContainer>
            )}
          </Spin>
        </div>
        {list?.length > 0 ? (
          <div className="flex justify-start items-center gap-2">
            <Button color="primary" variant="solid" loading={submitLoading} onClick={onPaperSubmit}>
              保存
            </Button>
            <Button onClick={goBack}>取消</Button>
          </div>
        ) : null}
      </div>
      {contextHolder}
      {contextModalHolder}
      <BankAddQuestion open={visible} onChange={onInsertBank} onCancel={setFalse} />
      <CreateQuestion
        open={visibleQuestion}
        paperId={query.id}
        onCancel={() => {
          setVisibleFalse();
          setCurrentEditQuestion(undefined);
        }}
        onChange={onInsertQuestion}
        data={currentEditQuestion}
      />
    </Card>
  );
}
