import { useState, useMemo } from 'react';
import { AddIcon } from '../addIcon';
import { InputNumber, message, Modal } from 'antd';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { AiOutlineDrag, AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { questionSelect, InsertType } from 'src/constants/exam';
import { QuestionType } from 'src/api/types/exam';
import styles from './index.module.less';
import { QuestionAnswer } from './components/QuestionAnswer';
import clsx from 'clsx';
import { IQuestionItem } from 'src/api/types/exam';
import { numberToLetter } from 'src/utils/tools';

interface IProps {
  id?: number;
  index: number;
  activeId: number;
  data: IQuestionItem & { score?: number };
  editQuestion?: (score?: number) => void;
  removeQuestion?: (i: string) => void;
  handleCreateTestpaper?: (type: InsertType) => void;
  handleEditTestpaper: (data: IQuestionItem) => void;
  openInsertBank: (type: InsertType) => void;
  onChangeActiveId: (i: number) => void;
}

export function Question(props: IProps) {
  const {
    index,
    activeId,
    data,
    editQuestion,
    removeQuestion,
    handleCreateTestpaper,
    handleEditTestpaper,
    openInsertBank,
    onChangeActiveId,
  } = props;
  const [scoreVal, setScoreVal] = useState(data?.score || 0);
  const [visible, setVisible] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: data.questionId!,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 1,
  };

  const isActive = useMemo(() => {
    return index === activeId;
  }, [index, activeId]);

  const titleNum = useMemo(() => {
    // 渲染题号
    if (index + 1 < 10) return '0' + (index + 1);
    return index + 1;
  }, [index]);

  const renderCorrent = () => {
    // 渲染正确内容
    if (data.questionType === QuestionType.Judgment) {
      return data.answer?.[0] === 1 ? '正确' : '错误';
    }
    if (
      [
        QuestionType.MultipleChoice,
        QuestionType.UnCertainMultiple,
        QuestionType.SingleChoice,
      ].includes(data.questionType)
    ) {
      return data.answer?.map((item) => numberToLetter(item + 1)).join('、');
    }
    if ([QuestionType.QuestionAndAnswer, QuestionType.FillInBlanks].includes(data.questionType)) {
      return data.textAnswer?.join('、');
    }
    return '';
  };

  return (
    <>
      <div
        onClick={() => onChangeActiveId?.(index)}
        id={`question_${data.questionId}`}
        className={clsx('relative bg-white rounded py-6 px-5')}
        ref={setNodeRef}
        style={{
          ...style,
          zIndex: isActive ? 50 : 1,
          boxShadow: isActive ? '0px 2px 8px rgba(0, 0, 0, 0.08)' : 'none',
        }}
        {...listeners}
        {...attributes}>
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start items-center gap-2 text-sm font-bold text-[#999999]">
            <AiOutlineDrag
              className="cursor-move"
              size="16"
              fill="#999999"
              strokeLinejoin="miter"
              strokeLinecap="butt"
            />
            <div>{titleNum}</div>
            <span>{questionSelect[data.questionType - 1]}</span>
          </div>
          <div className="flex justify-end items-center">
            分值
            <InputNumber
              className="ml-3"
              min={1}
              value={scoreVal}
              onChange={(val) => {
                const currentVal = val || 0;
                if (currentVal < 1) return;
                editQuestion?.(currentVal);
                setScoreVal(currentVal);
              }}
            />
          </div>
        </div>
        <div
          className={clsx(
            'mt-[10px] font-semibold text-base text-[#333333] whitespace-pre-wrap overflow-hidden',
            styles.testpaper_body,
          )}
          dangerouslySetInnerHTML={{ __html: data.body }}
        />
        <div className="mt-[10px]">
          <QuestionAnswer data={data} />
        </div>
        <div className="mt-4 p-4 bg-[#FAFAFA] rounded">
          <div
            className="font-semibold text-[#333333] whitespace-pre-wrap pb-3"
            style={{ paddingBottom: data.solution ? '12px' : '0px' }}>
            正确答案：{renderCorrent()}
          </div>
          {data?.solution ? (
            <div className="pt-3 border-0 border-t border-dashed border-[#e8e8e8]">
              {data.solution}
            </div>
          ) : null}
        </div>
        {activeId === index ? (
          <>
            <div className="absolute top-[-20px] left-1/2 translate-x--1/2">
              <AddIcon
                handleCreateTestpaper={() => handleCreateTestpaper?.(InsertType.top)}
                openInsertBank={() => openInsertBank?.(InsertType.top)}
              />
            </div>
            <div className="absolute bottom-[-20px] left-1/2 translate-x--1/2">
              <AddIcon
                handleCreateTestpaper={() => handleCreateTestpaper?.(InsertType.bottom)}
                openInsertBank={() => openInsertBank?.(InsertType.bottom)}
              />
            </div>
            <div
              className="absolute top-0 right-[-45px] w-10 bg-white rounded-md"
              style={{
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
              }}>
              <div
                className="h-10 cursor-pointer text-center leading-10 text-[#646a73]"
                onClick={() => {
                  if (data) {
                    handleEditTestpaper(data);
                    return;
                  }
                  message.error('题目错误，请删除后重新添加');
                }}>
                <AiOutlineEdit size="16" strokeLinejoin="miter" strokeLinecap="butt" />
              </div>
              <div
                className="h-10 cursor-pointer text-center leading-10 text-[#646a73]"
                onClick={() => {
                  setVisible(true);
                }}>
                <AiOutlineDelete size="16" strokeLinejoin="miter" strokeLinecap="butt" />
              </div>
            </div>
          </>
        ) : null}
      </div>
      <Modal
        open={visible}
        width={350}
        title="确定删除此题目吗"
        okText="再想想"
        cancelText="删除"
        onCancel={() => {
          removeQuestion?.(data?.questionId);
          setVisible(false);
        }}
        onOk={() => setVisible(false)}></Modal>
    </>
  );
}
