import { useMemo, useState } from 'react';
import { Popover, Menu, Tooltip } from 'antd';
import './index.less';
import { CreateButtonTypeEnum } from 'src/interface/exam';

interface IProps {
  placement?: any;
  handleCreateTestpaper: () => void;
  openInsertBank: () => void;
  className?: string;
  disabled?: boolean;
}

export function AddIcon(props: IProps) {
  const {
    placement = 'bottom',
    handleCreateTestpaper,
    openInsertBank,
    className,
    disabled = false,
  } = props;
  const [visible, setVisible] = useState(false);

  const handleMap = useMemo(() => {
    return {
      [CreateButtonTypeEnum.create]: handleCreateTestpaper,
      [CreateButtonTypeEnum.selectadd]: openInsertBank,
    };
  }, [handleCreateTestpaper, openInsertBank]);

  return !disabled ? (
    <Popover
      trigger="hover"
      zIndex={100}
      overlayClassName="add-dropdown-testpaper"
      placement={placement}
      destroyTooltipOnHide
      onOpenChange={(visible) => {
        setVisible(visible);
      }}
      content={
        <Menu
          items={[
            {
              label: '新建题目',
              key: CreateButtonTypeEnum.create,
            },
            {
              label: '从题库中添加题目',
              key: CreateButtonTypeEnum.selectadd,
            },
          ]}
          onClick={({ key: id }) => {
            handleMap[id as CreateButtonTypeEnum]?.();
          }}
        />
      }
      arrow>
      <div
        id="addIcon"
        className={
          visible
            ? `addicon-active ${className ? className : ''}`
            : `addIcon ${className ? className : ''}`
        }></div>
    </Popover>
  ) : (
    <Tooltip
      trigger="hover"
      zIndex={100}
      placement={placement}
      destroyTooltipOnHide
      title="请先填写考试信息"
      arrow>
      <div id="addIcon" className="addIcon addIcon-disabled"></div>
    </Tooltip>
  );
}
