import { ReactNode } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  MouseSensor,
  Modifier,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { IQuestionItem } from 'src/api/types/exam';

export interface IDragEndExtraParams {
  ids?: string[];
  oldIndex?: number;
  newIndex?: number;
}

interface IProps {
  /** 需要传递的列表 */
  list: IQuestionItem[];
  children: ReactNode;
  onDragEnd: (arr: IQuestionItem[], result: IDragEndExtraParams) => void;
}

export const DragContainer = ({ list = [], children, onDragEnd }: IProps) => {
  // 指定传感器，默认是全部
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5, // 按住不动移动5px 时 才进行拖拽
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // 拖拽结束
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = list.findIndex((item) => item.questionId === active.id);
      const newIndex = list.findIndex((item) => item.questionId === over?.id);
      const ids: string[] = list.map((item) => item.questionId)?.filter(Boolean) as string[];
      [ids[newIndex], ids[oldIndex]] = [ids[oldIndex], ids[newIndex]];
      const _val = arrayMove(list, oldIndex, newIndex);
      onDragEnd(_val, { ids, oldIndex, newIndex });
    }
  };
  const restrictToContainer: Modifier = ({ transform, activeNodeRect, containerNodeRect }) => {
    if (!containerNodeRect || !activeNodeRect) {
      return transform;
    }
    const { top, left, bottom, right } = containerNodeRect;
    const constrainedTransform = {
      ...transform,
      x: Math.min(Math.max(transform.x, left - activeNodeRect.left), right - activeNodeRect.right),
      y: Math.min(Math.max(transform.y, top - activeNodeRect.top), bottom - activeNodeRect.bottom),
    };
    return constrainedTransform;
  };
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToContainer]}>
      <SortableContext
        items={list?.map((i) => i.questionId!)}
        strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};
