import { useRef, useState } from 'react';
import { useMemoizedFn, useRequest } from 'ahooks';
import { getQuestionLibraryList, getQuestionList } from 'src/api/clients/exam';
import { Select, Table, Tag, Spin, Typography, Modal, Button, Pagination } from 'antd';
import Empty from 'src/components/Empty';
import type { ModalProps } from 'antd';
import { questionTypeMap } from 'src/constants/exam';
import { QuestionType } from 'src/api/types/exam';

interface IProps extends ModalProps {
  onChange?: (val: string[]) => void;
  onCancel?: () => void;
  onRefresh?: () => void;
}

const { Link, Paragraph } = Typography;

export function BankAddQuestion(props: IProps) {
  const { open, onCancel, onChange } = props;
  const [total, setTotal] = useState(0);
  const [pageInfo, setPageInfo] = useState({
    current: 1,
    pageSize: 10,
  });
  const [optionKey, setOptionKey] = useState('');
  const [multiIds, setMultiIds] = useState<string[]>([]);
  const selectedKeysRef = useRef(new Set()); // 用来保存所有选中的 key，避免重复
  const columns = () => [
    {
      key: 'body',
      dataIndex: 'body',
      title: '题目',
      render: (text: string) => {
        return <Paragraph ellipsis={{ rows: 3, tooltip: text }}>{text}</Paragraph>;
      },
    },
    {
      key: 'questionType',
      dataIndex: 'questionType',
      title: '题型',
      render: (type: number) => {
        return <Tag color="cyan">{questionTypeMap[type as QuestionType]}</Tag>;
      },
    },
  ];
  const gotoBank = () => {
    window.open('/exam/bank');
  };
  const gotoBankCreate = () => {
    window.open(`/exam/bank/exercise?id=${optionKey}`);
  };

  const { loading: contentLoading, data: options = [] } = useRequest(async () => {
    const result = await getQuestionLibraryList({ offset: 0, limit: 10000 });
    setOptionKey(result?.rows?.[0]?.questionLibraryId);
    return (result?.rows || []).map((item) => ({
      label: item.questionLibraryName,
      value: item.questionLibraryId,
    }));
  });

  const { loading, data: datasource = [] } = useRequest(
    async () => {
      if (!optionKey) {
        return [];
      }
      const result = await getQuestionList({
        offset: (pageInfo?.current - 1) * (pageInfo?.pageSize || 10),
        limit: pageInfo?.pageSize || 10,
        questionLibraryId: optionKey,
      });
      setTotal(result?.count || 0);
      return result?.rows || [];
    },
    { refreshDeps: [optionKey, pageInfo] },
  );

  const onCancelHandle = useMemoizedFn(() => {
    onCancel?.();
    setMultiIds([]);
    setPageInfo({
      current: 1,
      pageSize: 10,
    });
  });

  const onSubmit = useMemoizedFn(() => {
    onChange?.(multiIds);
    onCancelHandle?.();
  });

  return (
    <Modal
      title="添加固定题目"
      open={open}
      width={811}
      footer={
        <div className="flex justify-between items-center">
          <Pagination
            total={total}
            current={pageInfo?.current}
            pageSize={pageInfo?.pageSize}
            onChange={(page, pageSize) => {
              setPageInfo({
                current: page,
                pageSize,
              });
            }}
          />
          <div className="flex gap-2">
            <Button onClick={onCancelHandle}>取消</Button>
            <Button type="primary" onClick={onSubmit}>
              保存
            </Button>
          </div>
        </div>
      }
      onCancel={onCancelHandle}>
      <Spin spinning={contentLoading}>
        {contentLoading ? (
          <div className="insert_qt_empty"></div>
        ) : options?.length > 0 ? (
          <>
            <Select
              style={{ width: 240 }}
              placeholder="请选择题库"
              value={optionKey}
              options={options}
              onChange={(v) => v && setOptionKey(v)}
            />
            <Table
              style={{ marginTop: '16px' }}
              rowKey="questionId"
              columns={columns()}
              loading={loading}
              dataSource={datasource}
              size="small"
              scroll={{ x: 600 }}
              pagination={false}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: multiIds,
                onChange: (selectedRowKeys) => {
                  // 计算新选中的 `key`
                  const updatedSelectedKeys = new Set([
                    ...selectedKeysRef.current,
                    ...selectedRowKeys,
                  ]);

                  // 更新全局选中项 multiIds
                  setMultiIds([...(updatedSelectedKeys as unknown as string[])]);
                  selectedKeysRef.current = updatedSelectedKeys; // 保存最新选中的行的 key
                },
              }}
              locale={{
                emptyText: (
                  <Empty
                    description={
                      <div className="empty-text">
                        还没有题库内容，请先 <Link onClick={gotoBankCreate}>创建题目</Link>
                      </div>
                    }
                  />
                ),
              }}
            />
          </>
        ) : (
          <div
            className="question-empty"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img
              style={{ width: '510px', height: '200px' }}
              src="https://lf1-cdn-tos.bytegoofy.com/obj/goofy/ad/kaido/sop/resource/b123b5861f94d24f336c.svg"
            />
            <div className="empty-text">
              还没有题库内容，请先 <Link onClick={gotoBank}>创建题库</Link>
            </div>
          </div>
        )}
      </Spin>
    </Modal>
  );
}
